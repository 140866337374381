import $ from 'jquery';

import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';

// https://github.com/zurb/foundation-sites/issues/10336
Foundation.addToJquery($);

// TODO DOESN'T WORK - WEBPACK CAN'T RESOULVE FOUNDATAION PATH
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
// import './lib/foundation-explicit-pieces';

import 'tablesaw/dist/tablesaw.jquery';

import libs from './lib/dependencies';
window.libs = libs;


// SVG Injector
// Elements to inject
var mySVGsToInject = document.querySelectorAll('img.inject-me');

// Options
var injectorOptions = {
  evalScripts: 'once',
  // pngFallback: '../img/fallback'
};

var afterAllInjectionsFinishedCallback = function (totalSVGsInjected) {
  // Callback after all SVGs are injected
  // console.log('We injected ' + totalSVGsInjected + ' SVG(s)!');
};

var perInjectionCallback = function (svg) {
  // Callback after each SVG is injected
  // console.log('SVG injected: ' + svg);
};

// create injector configured by options
var injector = new libs.svgInjector(injectorOptions);

// Trigger the injection
injector.inject(
  mySVGsToInject,
  afterAllInjectionsFinishedCallback,
  perInjectionCallback
);

// tablesaw table plugin setup
$(function () {
  $(document)
    .foundation()
    .trigger('enhance.tablesaw');
});

var TablesawConfig = {
  swipeHorizontalThreshold: 15
};

// global vars - set while build
window.packageVersion = '1.0.00';
var REST_URLS = {};
REST_URLS.deliveryArea = 'http://localhost:3000/delivery-area';
REST_URLS.products = 'http://localhost:3000/products?category-id=';
REST_URLS.units = 'http://localhost:3000/units?product-id=';
window.REST_URLS = REST_URLS;

// blocks
require('./blocks/sticky-shrinknav');
require('./blocks/ecommerce-product-image-gallery.js');

// custom scripts
require('./custom/common-scripts.js');