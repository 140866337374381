
(function ($) {

    // strict mode
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Strict_mode
    'use strict';

    // listen to scroll event abd make submit row follow sticky header
    // Reference: http://www.html5rocks.com/en/tutorials/speed/animations/
    var last_known_scroll_position = 0;
    var ticking = false;

    function makeSubmitSticky(scroll_pos) {

        var isStickyHeader = $('header .sticky-container .sticky').hasClass('is-stuck');

        if (isStickyHeader) {
            $('.follow-sticky').addClass('sticky-wrapper');
        } else {
            $('.follow-sticky').removeClass('sticky-wrapper');
        }
    }

    window.addEventListener('scroll', function (e) {

        last_known_scroll_position = window.scrollY;

        if (!ticking) {

            window.requestAnimationFrame(function () {
                makeSubmitSticky(last_known_scroll_position);
                ticking = false;
            });

            ticking = true;

        }

    });

    // Global function for adding even and odd classes to accordion form rows
    window.alternateAccordionFormRows = function () {
        var accordionContent = $('.form-wrapper.accordion-content');
        accordionContent.each(function (i) {
            $(this).find('.row.form-elements.alternate:odd').removeClass('even').addClass('odd');
            $(this).find('.row.form-elements.alternate:even').removeClass('odd').addClass('even');
        });
    };

    // ready function
    $(document).ready(function () {

        // toggle js utility selector class
        $('html').removeClass('no-js')
            .addClass('js');

        // check autofill on load
        $(function () {
            setTimeout(function () {
                $('.js-input').each(function () {
                    if ($(this).val()) {
                        $(this)
                            .parents('.input-group')
                            .addClass('active')
                            .addClass('valid-input')
                            .find('i.success').show()
                        ;

                    }
                });
            }, 100);
        });

        var size = Foundation.MediaQuery.current;

        var _desktop = function () {
            $('.mobBTN, .top-bar').removeClass('active');
        };

        var _mobile = function () {

            // mobile navigation click on title
            $(function () {
                var navtitle = $('.main-navigation.mobile .is-accordion-submenu-parent');
                navtitle.click(function () {
                    var attr = $(this).attr('aria-expanded');
                    if (attr == 'false') {
                        var url = $(this).find(' > a').attr('href');
                        window.open(url, '_self');
                    }
                });
            });
        };

        if (size == "small") {
            _mobile();
        } else {
            _desktop();
        }

        $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {

            if (event) {
                if (newSize == "small") {
                    _mobile();
                } else {
                    _desktop();
                }
            }
        });

        // global for shariff plugin
        window.initSelectric = function () {
            $('select').selectric({
                arrowButtonMarkup: '<i class="fa fa-angle-down" aria-hidden="true"></i>',
                disableOnMobile: false
            });
        };

        // call global function
        initSelectric();


        // handle login
        $(function () {
            var $form = $('.js-loginform'),
                $input = $('.input-group input.js-input'),
                $inputPw = $('.input-group input.js-input[type=password]'),
                max = $('.js-loginform input.js-input').length,
                $close = $('.input-group .close'),
                validfields,
                browser = navigator.userAgent.toLowerCase();

            // listen to key actions in pw input
            $inputPw.on('keyup', function (e) {
                // slog(e.keyCode);
                if (e.keyCode > 47) {
                    $(this).trigger('valid.zf.abide');
                }
            });

            // listen to clipboard paste in pw input
            $inputPw.on('paste', function () {
                $(this).trigger('valid.zf.abide');
            });

            // check if autocompleted on load
            $input.each(function () {
                if ($(this).val()) {
                    $(this)
                        .parents('.input-group')
                        .addClass('active')
                    ;
                }
            });

            // set .active on focus
            $input.bind('focus', function () {
                $(this)
                    .parents('.input-group')
                    .addClass('active')
                ;
            });

            // handle firefox
            if (browser.indexOf('firefox') > -1) {
                $input.change(function () {
                    if ($(this).val()) {
                        $(this)
                            .parents('.input-group')
                            .addClass('active')
                        ;
                    }
                });
            }

            // clear input field
            $close.bind('click', function () {
                $(this)
                    .parents('.input-group')
                    .removeClass('active')
                    .removeClass('invalid')
                    .find('input')
                    .val('')
                    .removeAttr('data-invaild')
                    .removeClass('is-invalid-input')
                    .parents('.input-group')
                    .find('.form-error')
                    .removeClass('is-visible')
                ;
            });


            // remove classes on invalid
            $input.on('invalid.zf.abide', function (ev, elem) {
                $(this)
                    .parents('.input-group')
                    .addClass('invalid')
                    .removeClass('valid-input')
                    .find('i.success').hide()
                ;
                validfields = $('.input-group.valid-input').length;
                if (validfields !== max) {
                    if (!$('.js-loginform .button').hasClass('disabled')) {
                        $('.js-loginform .button')
                            .addClass('disabled')
                            .attr('disabled', true)
                        ;
                    }
                }
            });

            // add classes on valid
            $input.on('valid.zf.abide', function (ev, elem) {
                $(this)
                    .parents('.input-group')
                    .removeClass('invalid')
                    .addClass('valid-input')
                    .find('i.success').show()
                ;
                validfields = $('.input-group.valid-input').length;
                if (validfields === max) {
                    $('.js-loginform .button')
                        .removeClass('disabled')
                        .removeAttr('disabled')
                    ;
                }
            });

            // reset on focus out when empty
            $input.focusout(function () {
                if (!$(this).val()) {
                    $(this)
                        .parents('.input-group')
                        .find('.close')
                        .trigger('click')
                    ;
                }
            });

            // on pressed tab key
            $input.on('keydown', function (e) {
                if (e.keyCode === 9) {
                    // focus next input
                    if ($(this).parents('.input-group').hasClass('js-usermail')) {
                        $('.js-password input')
                            .parents('.input-group')
                            .addClass('active')
                        ;
                    }
                }
            });

            // set focus
            let $userNameInput = $('#login_form_username');
            $userNameInput.focus();
        });

        // flyout
        $(function () {

            // flyout navigation
            var $primaryNav = $('.main-navigation ul.menu li'),
                $secondaryNav = $('ul.flyout'),
                hoverTimeout,
                timeout = 250;

            $primaryNav.mouseover(function () {

                var $ul = $(this).find('> ul');
                var $this = $(this);

                // clear timeout
                if (hoverTimeout) clearTimeout(hoverTimeout);
                hoverTimeout = setTimeout(function () {
                    // reset
                    $secondaryNav.hide();
                    $primaryNav.removeClass('is-open');
                    // show content
                    $this.addClass('is-open');
                    $ul.show();
                    Foundation.reInit('equalizer');
                }, timeout);
            });

            $secondaryNav.mouseover(function () {
                // clear timeout
                if (hoverTimeout) clearTimeout(hoverTimeout);
            });

            $primaryNav.mouseleave(function () {
                // clear timeout
                if (hoverTimeout) clearTimeout(hoverTimeout);
                var $this = $(this);
                // set timeout
                hoverTimeout = setTimeout(function () {
                    $primaryNav.removeClass('is-open');
                    // close flyout
                    $secondaryNav.hide();
                }, timeout);
            });

            $secondaryNav.mouseleave(function () {
                if (hoverTimeout) clearTimeout(hoverTimeout);
                // set timeout
                hoverTimeout = setTimeout(function () {
                    $primaryNav.removeClass('is-open');
                    // close flyout
                    $secondaryNav.hide();
                }, timeout);
            });
        });

        // mobile BTN toggle
        $(function () {
            var btn = $(".mobBTN");
            btn.click(function () {
                $(".main-navigation.mobile").slideToggle("slow");
                $(".mobBTN .fa").toggle();
            });
        });

        // toggle sitemap
        $(function () {
            var sM = $('section.sitemap');
            var sC = sM.find('.content');
            sM.find('> .seen').click(function () {
                toggleArrow();
                $(this).toggleClass('seen');
            });

            function toggleArrow() {
                sC.slideToggle(500, function () {
                });
            }

        });

        // Back to top link
        $(function () {
            var scrollBtn = $('.scroll-to-top');
            var scrollContainer = $('main.cell > .cell');
            scrollBtn.mouseover(function () {
                $(this).css('display', 'inline-block');
            });
            scrollContainer.scroll(function () {

                // console.log($(this).scrollTop());

                if ($(this).scrollTop() > 60) {
                    scrollBtn.fadeIn('slow');
                } else {
                    scrollBtn.fadeOut('slow');
                }
            });
            scrollBtn.click(function () {
                scrollContainer.animate({
                    scrollTop: 0
                }, 800);
                return false;
            });
        });

        // Play audio onscroll for landing page
        $(function () {
            var jrrfAudio = $('#jrrfAudio');
            var scrollRange = 50;

            var playing = false;
            var audioElm = jrrfAudio[0];

                if(jrrfAudio.length) {

                    $(window).scroll(function(scr) {
                        var pageScroll = $(window).scrollTop();
                        if(!playing && pageScroll > scrollRange){
                            var promise = audioElm.play();
                            if (promise !== undefined) {
                                promise.then(_ => {
                                    // Autoplay started!
                                    playing = true;
                                    scr.preventDefault();
                                }).catch(error => {
                                    // Autoplay was prevented.
                                    // Show a "Play" button so that user can start playback.
                                    jrrfAudio.show();
                                });
                            }

                        }
                    });
             }

        });

        // Trash icon calls delete url
        $(function () {
            var deleteBtn = $('.accordion-item .delete');
            deleteBtn.on('click', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if (deleteBtn.data('delete-url')) {
                    document.location.href = deleteBtn.data('delete-url');
                }
            });
        });

        // copy input vals 2 text and hide input
        $(function () {
            var $inputContainer = $('.hide-input-style'),
                inputVal = '';
            $inputContainer.find('input').each(function () {
                inputVal += $(this).val() + ' ';
            }).hide();
            $inputContainer.text(inputVal);
        });

        // initial call
        alternateAccordionFormRows();

        // alert boxes handling
        $(function () {
            var $alertBoxes = $('div.flash > .alert, div.flash-messages > .alert');
            $alertBoxes.append('<a href="#" class="close">&times;</a>')
                .on('click', function () {
                    $(this).fadeOut(300);
                });
        });

        // set links for dashboard panels
        $(function () {
            var $dashboardPanels = $('div.panel.callout');
            if ($dashboardPanels.length) {
                $dashboardPanels.each(function () {
                    var url = $(this).data('link-url');
                    if (url) {
                        $(this).on('click', function () {
                            window.location.href = url;
                        });
                    }
                });
            }
        });

        // takes the val of first appearance of data-jump-to and jumps to that id it is set
        $(function () {

            // adjust timeout to accordion behavior
            var anitime = 750;

            var jumpTarget = $('[data-jump-to]').attr('data-jump-to');

            if (jumpTarget) {
                scrollto(jumpTarget);
            }

            function scrollto(element) {
                var $target = $('#' + element);
                if ($target.length) {
                    setTimeout(function () {
                        $('html, body').animate({scrollTop: ($target.offset().top)}, 'slow');
                    }, anitime);
                }
            }
        });

        // tax switch for brutto / netto tax input
        $(function () {
            // tax switch element
            var $taxSwitch = $('.switch.tax .switch-input');

            $taxSwitch.on('change', function () {
                if ($(this).data('price-mode-param')) {
                    var url = window.location.href.split('?')[0] + $(this).data('price-mode-param');
                    location.href = url;
                }
            });
        });


        // dynamic modal setup (reveal)
        $(function () {
            $('[data-dynamic-modal]').click(function (e) {

                e.preventDefault();

                let url = $(this).data('dynamic-modal'),
                    $modal = $('#revealVessel');

                console.log(url);
                console.log($modal.length);

                $.ajax(url)
                    .done(function(resp){
                        $modal.find('[data-dynamic-modal-insert]').html(resp);
                        var popup = new Foundation.Reveal($modal);
                        popup.open();
                    })
                    .fail(function( xhr, ajaxOptions, thrownError ) {
                        console.error("Reveal request failed: " + xhr.responseText);
                    })
            });
        });




        // mobile nav toggle behaviour
        // checks if the respective menu is open and closes other menus
        $(function() {
            let $mobileMenuToggle = $('.mobile section');
            let $mobileNavContent = $('nav.mobile');
            if($mobileMenuToggle.length) {
                $mobileMenuToggle.on('toggled.zf.responsiveToggle', function(e) {
                    let current = $(this).data('responsive-toggle');
                    // hide others
                    $mobileNavContent.each(function() {
                       // console.log($(this).attr('id'));
                       if(current !== $(this).attr('id')) {
                           $('#' + $(this).attr('id')).hide();
                       }
                    });
                });
            }
        });

        // pelican reclaimer :)
        $(function() {
            let $pelican = $('#pelican-reclaims');
            $pelican.on('click', function() {
                $pelican.find('figcaption').show(333);
                $pelican.off('click');
                setInterval(function() {
                    $pelican.find('figcaption').hide(333);
                }, 3333);
            });
        });


        // print function cta buttons
        $(function () {
            var ctaBtn = $('.button[data-print]');
            ctaBtn.on('click', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                window.print();
            });
        });


        // show product image on hover
        $(function () {
            var $thumbs = $('.thumbnail[data-product-image]');
            var aniTime = 333;

            $thumbs.each(function() {
                let url = $(this).data('product-image');

                $(this).hover(
                    function(e) {
                        let $figure = $(this).parent();
                        let $bigPicture = $figure.find('.big');

                        if($bigPicture.length) {
                            $bigPicture.show(aniTime);
                        } else {
                            $figure.append('<img class="big" style="left:' + $figure.width() + 'px" src="' + url + '"/>');
                            $figure.find('.big').show(aniTime);
                        }
                    },
                    function(e) {
                        let $figure = $(this).parent();
                        let $bigPicture = $figure.find('.big');

                        if($bigPicture.length) {
                            $bigPicture.hide(aniTime);
                        }
                    }
                );
            });
        });


        // submit row handling
        $(function() {
            let hasSubmitRow = $('[data-submit-row]').length;
            let $submitRow = $('#submitRow');
            let $submitRowCancel = $('#submitRowCancel');
            let $submitRowSubmit = $('#submitRowSubmit');
            let form = window.document.forms[0];

            if(hasSubmitRow) {

                // make visible
                $submitRowCancel.show();
                $submitRowSubmit.show();
                $submitRow.show();

                $submitRowCancel.on('click', function(e) {

                    e.preventDefault();
                    e.stopImmediatePropagation();

                    // reset
                    if (form) {
                        form.reset();
                    }
                });

                $submitRowSubmit.on('click', function(e) {

                    // submit
                    if (form) {
                        submitform(form);
                    }
                });

                function submitform(form) {
                    // Check if valid using HTML5 checkValidity() builtin function
                    if (form.checkValidity()) {
                        // console.log('valid');
                        form.submit();
                    } else {
                        $('[type="submit"]').click();
                        // console.log('not valid');
                    }
                    return false;
                };
            }
        });

    });

})($);